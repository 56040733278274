<template>
    <div style="position:relative;"  class="crane-and-rigging">
        <div class="container landing-banner-container" style="padding-top: 7.5rem">
                <div class="left-banner">
                    <h1 style="font-size: 3.625rem;font-weight: bold;line-height: 1.21;color: #021222;padding-bottom: 1.875rem;"><span style="color:#00c8d8;">Remote Approval:</span> Simplify Decision-Making Process</h1>
                <div>
                 <p><b>Field workers often face challenges in obtaining timely approvals for their remote work, especially when supervisors, managers, or leaders are geographically dispersed. However, the integration of Record TIME which facilitates remote approval of digital forms and dockets, has emerged as a game-changer. This key feature of Record TIME not only enhances workflow efficiency but also addresses longstanding issues associated with delays in project timelines and communication gaps. </b></p>
                <p>We offer an organised process that enables the project manager to review and approve any documents from anywhere, at any time. Once the document has been uploaded, the project team members can view, analyse, and comment on the documents remotely. Remote participation in the approval process ensures that everyone is on the same page. Moreover, you can track the whole approval history, including remarks, changes, and decisions, which simplifies the process of maintaining a detailed record for compliance purposes.</p>
                <p>By digitising the approval process, we eliminate the need for physical paperwork and expedite the overall workflow. Moreover, our system saves you time, reduces admin work, and speeds up projects.</p>    
            </div>
                <br><br>
                    <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
                        @click="redirectToLink('https://recordtime.com.au/custom-invoice-book')" class="mr-7 btn-1" elevation="0">
                        Read More
                    </v-btn>
            <v-btn height="56" class="get-demo-btn"  color="white" @click="getDemo()">
                Get A Demo Now
            </v-btn>
            <br><br>
            </div>
            <div  class="right-banner" style="text-align: right;">
                <img :src="`${publicPath}assets/features-image/remote-approval-of-docket.webp`" alt="construction job site manager using record time for smooth operation"  style="max-width: 90%;">
            </div>
            <div style="clear: both;"></div>
        </div>
        <v-container>
            <div class="gray-container"> 
                <img :src="`${publicPath}assets/features-image/remote-approval-of-invoices.webp`" alt="generate professional digital invoices and obtain remote approvals" style="max-width: 500px;float: left;">
            <div style="">
                <h2>Generate Professional Digital Custom Invoices and Obtain Remote Approval</h2>
                <p style="text-align:left;">Say goodbye to the delays of manual invoice approval processes. Effortlessly collaborate and communicate with stakeholders and team members through our intuitive platform. No more chasing down colleagues for physical signatures or relying on slow email threads. 
                    <br/><br/> With Record TIME, you can generate digital <a href="https://recordtime.com.au/custom-invoice-book">custom invoices</a> and send them for remote approval. This can be done seamlessly within the system itself or, alternatively, by crafting personalised emails. Certainly, we are revolutionising how businesses handle invoice approvals. We offer fast remote approval and improved project management.
              </p>

            <br/><br/>  
            <h3 style="text-align:left;">Ready to take the leap? Contact us now to learn more and create a demo of our lightning-fast remote approval solution for remote worker.</h3><br/>
            </div>      
        </div>
        <br/><br/>  
        <br/>  
        </v-container>
        <h2 style="text-align:center;">Key Benefits of Remote Approval</h2>
        <br>
        <img :src="`${publicPath}assets/features-image/benefits-of-remote-approvals.webp`" alt="benefits of remote approval with record time"  style="max-width: 100%;">
       <br>
        <p style="text-align:center;">Overall, remote document approvals are a flexible, efficient, and secure way to speed up approvals in the digital age. Maintain detailed audit trails that record the history of document approval process, while avoiding the time-consuming hurdles of traditional methods.</p>
          <get-demo-modal v-if="getDemoModal" :demoEmail="demoEmail" @excEmit="excEmit" @getDemoModalClose="getDemoModalClose" @demoResponse="demoResponse" url="https://www.youtube.com/embed/62kV7CIU_F4"/>
        </div>
        

</template>
<style lang="scss">
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
        }
    }
    .left-banner{
        width: 45%; 
        float: left;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .right-banner{
        width: 55%; 
        float: right;
        padding: 0px 20px 20px;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
            h1{ font-size: 40px !important;}
        }
    }
    .heavy-civil{
        .banner-img{
            @media only screen and (max-width: 600px) {
            position: relative !important;
            }

            @media only screen and (max-width: 1280px) {
                width: 100% !important;
                top: 20px !important;
                height: auto !important;
            }
        }
        p{
            @media only screen and (max-width: 1280px) {
                padding-bottom: 0px !important;
            }
        }
    }
    .banner-img{
        @media only screen and (max-width: 600px) {
            position: relative !important;
        }

        @media only screen and (max-width: 1280px) {
            position: relative !important;
        }
    }
    .gray-container{
        background: #F8F9FB;
        padding: 20px;
        margin-top: 30px;
        h3 {
            font-size: 32px;
            font-weight: bold;
            text-align: center;
            color: $black-pearl;
            margin-bottom: 15px;

            span {
            color: $faux-dark-turquoise;
            }
        }
        p{
            margin-top: 40px;
            text-align: center;
            color: #000;
        }
        .list{
            h1{
                color: #00C8D8;
            }
            p{
                color: #000;
                text-align: left;
            }
        }
    }
    .left-blog1{
      float:left;
      max-width: calc(100% - 600px);
      padding-right: 15px;
      @media only screen and (max-width: 1280px) {
        max-width: 100%;
      }
    }
    .right-blog1{
      width: 600px;
      float:right;
      @media only screen and (max-width: 1280px) {
        width: 100%;
      }
      img{
        max-width: 600px;
        width: 100%;
        @media only screen and (max-width: 1280px) {
            max-width: 100%;
        }
      }
    }
    @media only screen and (max-width: 600px) {
      .left-blog1{
        float: none;
        max-width: 100%;
        padding-right: 0px;
      }
      .right-blog1{
        float: none;
        max-width: 100%;
      }
    }
</style>
<script>
import GetDemoModal from '@/components/GetDemoModal.vue';
export default{
    metaInfo: {
    title: 'Remote Approval of Digital Forms and Dockets | Record TIME',
    // titleTemplate: 'Record TIME → %s',
    meta: [
      {name: 'name', content: ''},
      {name: 'description', content: 'Remote approval for remote worker to simplify the decision-making. Generate digital custom invoices and send them for approval remotely.'},
      {name: 'keyword', content: 'remote approval'}
      ],
    link: [
      { rel: 'canonical', href: 'https://recordtime.com.au/remote-approval' }
    
    ]
  },
  components: {
        GetDemoModal
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            getDemoModal: false,
        }
    },
    methods: {
        redirectToLink(url) {
        window.open(url, '_blank');
        },

        getDemoModalClose(){
            this.getDemoModal = false;
            this.demoEmail = '';
        },
        getDemo(){
            this.getDemoModal = true;
        }
    }
}
</script>
